import { Component } from "@angular/core";

@Component({
    selector: 'app-webinar-sign-up',
    templateUrl: './webinar-sign-up.component.html',
    styleUrls: ['./webinar-sign-up.component.scss']
})
export class WebinarSignUpComponent {
    sessions = [
        {
            date: "Wednesday, 9/4 4 pm ET",
            link: "https://events.teams.microsoft.com/event/65e6f76b-116b-4b4c-8c8a-9cfb2af17dd5@d3a74ac8-efe4-4fe8-b707-b1bf8c6a25bd"
        },
        {
            date: "Wednesday, 9/4 6 pm ET",
            link: "https://events.teams.microsoft.com/event/57d44e40-e039-4c39-b25d-4071c7054fe3@d3a74ac8-efe4-4fe8-b707-b1bf8c6a25bd"
        },
        {
            date: "Thursday, 9/12, 6 pm ET",
            link: "https://events.teams.microsoft.com/event/38f0c5b7-d6a9-49e3-8217-b0a5a94a7110@d3a74ac8-efe4-4fe8-b707-b1bf8c6a25bd"
        },
        {
            date: "Wednesday, 9/18, 6 pm ET",
            link: "https://events.teams.microsoft.com/event/b2ef8cea-5ea4-44e7-8da9-9563d3593e95@d3a74ac8-efe4-4fe8-b707-b1bf8c6a25bd"
        },
        {
            date: "Tuesday, 9/24, 6 pm ET",
            link: "https://events.teams.microsoft.com/event/1c6b1993-6f13-4bbc-bd1d-071992677958@d3a74ac8-efe4-4fe8-b707-b1bf8c6a25bd"
        },
        {
            date: "Monday, 9/30, 6 pm ET",
            link: "https://events.teams.microsoft.com/event/95971976-3e69-4263-b322-ecc5d3c0f3af@d3a74ac8-efe4-4fe8-b707-b1bf8c6a25bd"
        }
    ];

    constructor() {}

}
